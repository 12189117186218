import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Lazy loading the components
const HomePage = lazy(() => import("./Pages/HomePage"));
const CareersPage = lazy(() => import("./Pages/CareersPage"));
const PrivacyPolicyPage = lazy(() => import("./Pages/PrivacyPolicyPage"));
const Page404 = lazy(() => import("./Pages/Page404"));
const WeAreInNewsPage = lazy(() => import("./Pages/WeAreInNewsPage"));

const Routers = () => {
    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="careers" element={<CareersPage />} />
                    <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
                    <Route path="weareinnews" element={<WeAreInNewsPage />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Suspense>
        </Router>
    );
}

export default Routers;
