import "./App.scss";
import './App.css';
import Routers from './Routers';

import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BreakpointProvider } from 'react-socks';

function App() {
  return (
    <>
      <BreakpointProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="dark"
          transition={Slide}
        />
        <Routers />
      </BreakpointProvider>
    </>
  );
}

export default App;
